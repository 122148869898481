import * as React from "react"
import { graphql } from "gatsby"
import { Site } from "@life-without-barriers/gatsby-common"
import {
  PreStyledComponents,
  Variables,
  RelatedPagesContent,
  IconRelationships,
  IconImaginative,
  IconRespectful,
  IconResponsive,
  IconCourageous,
  Section,
  Container,
  Row,
  Box,
  Heading,
  DividedList
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"

import ContentLayout from "../../components/about-us/ContentLayout"

const { IconWrapper, ColouredSpan } = PreStyledComponents
const { DiversityAndInclusion, Careers, WePutChildrenFirst } =
  RelatedPagesContent

const {
  redMedium,
  disabilityBlueMedium,
  youthPinkMedium,
  corporateGreenMedium,
  yellow
} = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    ogImage: IGatsbyImageData
  }
}

interface LwbValueItemProps {
  icon: React.ReactElement
  value: string
  text: string
  color: string
}

const LwbValueItem = ({ icon, value, text, color }: LwbValueItemProps) => (
  <div className="lwb-value-item flex flex-column flex-row-l justify-between items-center tc tl-l">
    <IconWrapper
      className="flex justify-center items-center"
      color={color}
      size="90"
    >
      {icon}
    </IconWrapper>
    <div className="w-100 w-30-l ts-display-3 fw7 pv3 pv4-l pl4-l">
      <ColouredSpan color={color}>{value}</ColouredSpan>
    </div>
    <div className="w-100 w-60-l pl3-l">{text}</div>
  </div>
)

const OurValuesPage = ({ location, data: { site, ogImage } }: Props) => (
  <ContentLayout
    location={location}
    site={site}
    contentProps={{
      metaTitle: "Our values",
      metaDescription:
        "At Life Without Barriers our values are our true compass, they define how we work.",
      ogImage,
      title: "Our values",
      tagline: "Our people and values make the difference",
      relatedPages: [DiversityAndInclusion, Careers, WePutChildrenFirst]
    }}
  >
    <div>
      <Section background="bg-lwb-grey-xxx-light">
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>Our reason for being</Heading>
              <p>
                Life Without Barriers has a strong history which defines us and
                gives us focus and determination for the future. Our purpose is
                to partner with people to change lives for the better. It is
                reflected in our values, portrayed in our vision, intertwined
                within our culture and is at the forefront of everything we do.
              </p>
              <p>
                We are driven and determined, creating an environment where
                anything is possible. Together we are formidable, we gain
                strength from one another, obtain wisdom from our diversity and
                are united in our pursuit of creating something unique with the
                people we support. We are passionate and promote an innovative
                mindset, we think outside the box and strive to make a
                difference – we get fired up by our purpose, and we promote a
                single focus: people we support are first - always.
              </p>
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box>
            <Heading size={2} className="tc">
              Our Values
            </Heading>
            <DividedList>
              <>
                <li className="pv4">
                  <LwbValueItem
                    color={disabilityBlueMedium}
                    icon={
                      <IconRelationships width="60" height="60" ariaHidden />
                    }
                    value="Relationships"
                    text="Relationships come first. It's more than just working together, it's understanding one another, making lasting connections and building trust. Our relationships are strong and enduring and have the power to impact real change."
                  />
                </li>
                <li className="pv4">
                  <LwbValueItem
                    color={redMedium}
                    icon={<IconCourageous width="60" height="60" ariaHidden />}
                    value="Courage"
                    text="We have the courage to challenge the status quo and the resolve to do something about it. We are a collective who encourage people we support to lead the change they want in their lives."
                  />
                </li>
                <li className="pv4">
                  <LwbValueItem
                    color={yellow}
                    icon={<IconImaginative width="60" height="60" ariaHidden />}
                    value="Imagination"
                    text="We know the importance of imagination to see what is possible, what works. We are always open to new ways of doing things - And we do so openly and willingly."
                  />
                </li>
                <li className="pv4">
                  <LwbValueItem
                    color={corporateGreenMedium}
                    icon={<IconResponsive width="60" height="60" ariaHidden />}
                    value="Responsiveness"
                    text="We respond appropriately and promptly to individual, and community needs, and we appreciate the importance of agility and partnering to find solutions."
                  />
                </li>
                <li className="pt4 pb5">
                  <LwbValueItem
                    color={youthPinkMedium}
                    icon={<IconRespectful width="60" height="60" ariaHidden />}
                    value="Respect"
                    text="We are respectful of people's rights, the viewpoints of others and try to see the world from a diverse and inclusive perspective."
                  />
                </li>
              </>
            </DividedList>
            <div className="pv3">
              <blockquote className="color-lwb-theme-medium ts-display-4 tc">
                “With imagination, respect, courage and responsiveness as our
                compass in all relationships, we never forget the value of trust
                placed in us to provide reliable supports that exceed
                expectations. Our values are our true compass and define how we
                work.”
              </blockquote>
              <div className="color-lwb-theme-medium fw7 ts-display-5 tc">
                Claire Robbs, Chief Executive
              </div>
            </div>
          </Box>
        </Row>
      </Container>
    </div>
  </ContentLayout>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { eq: "about-us/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED)
      }
    }
  }
`

export default OurValuesPage
