import {
  Helmet,
  Layout,
  Theme,
  Banner,
  CardCenteredInterface,
  NavigationTiles,
  Section,
  Container,
  routesObject
} from "@life-without-barriers/react-components"
import React, { ReactElement } from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { IGatsbyImageData } from "gatsby-plugin-image"

export interface ContentLayoutProps {
  location: { pathname: string }
  children: ReactElement | ReactElement[]
  site: Site
  contentProps: ContentProps
}

interface ContentProps {
  metaTitle: string
  metaDescription: string
  ogImage: IGatsbyImageData
  title: string
  relatedPages: CardCenteredInterface[]
  tagline?: string
  centerBanner?: boolean
}

const ContentLayout = ({
  location,
  contentProps: {
    metaTitle,
    metaDescription,
    ogImage,
    title,
    tagline,
    relatedPages
  },
  children,
  site
}: ContentLayoutProps) => (
  <>
    <Helmet
      title={`${metaTitle} | ${site.siteMetadata.title}`}
      description={metaDescription}
      siteUrl={site.siteMetadata.siteUrl}
      path={location.pathname}
      image={ogImage}
    />
    <Layout theme={Theme.corporateThemeFull}>
      <Banner
        title={title}
        subTitle={tagline}
        breadcrumbs={[
          routesObject.home,
          {
            to: "/about-us/",
            text: "About us",
            title: "About us"
          }
        ]}
      />
      {children}

      <Section background="bg-hex-image">
        <Container>
          <NavigationTiles
            heading={
              <h2 className="color-lwb-theme-medium ts-display-3 tc fw8">
                Learn more about Life Without Barriers
              </h2>
            }
            currentPathname={location.pathname}
            pages={relatedPages}
          />
        </Container>
      </Section>
    </Layout>
  </>
)

export default ContentLayout
